.TransactionHistory{
    padding: 3rem 0;
    width: 85%;
    max-width: 85rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transactions-tag{
    font-family: "Bebas Neue Regular";
    font-size: 2.5rem;
    color: white;
}

.transactions{
    width: 100%;
    margin-top: 1.7rem;
    display: flex;
    flex-direction: column-reverse;
}

.transaction{
    height: 2.7rem;
    width: 100%;
    background-color: #121212;
    border-radius: 5px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 1rem
}

.transaction:last-child{
    margin-top: 0;
}

.indicator{
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: blueviolet;
    margin-left: 1rem;
}

.hash{
    margin-left: 2rem;
    font-family: "Bebas Neue Book";
    font-size: 1.3rem;
    color: white;
    display: flex;
    align-items: center;
}

.hash-link{
    margin-left: 1rem;
    font-family: "Bebas Neue Regular";
    font-size: 1.2rem;
    color: white;
    text-decoration: underline;
    margin-top: 3px;
}

a{
    color: white;
}

.logs{
    margin-right: 3rem;
    margin-left: auto;
    font-family: "Bebas Neue Book";
    font-size: 1.3rem;
    color: white;
    display: flex;
    align-items: center;
}

.log{
    font-size: 1.3rem;
    height: fit-content;
    margin: 0 0.5rem;
    margin-top: 3px;
}

.empty{
    margin: 2rem 0;
    font-family: "Bebas Neue Book";
    font-size: 1.5rem;
    color: white;
    text-align: center;
}