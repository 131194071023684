.Bridge{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    border-radius: 5px;
    border: 1px solid white;
    padding: 1.4rem;
}

.bridge-tag{
    font-size: 2.5rem;
    font-family: "Bebas Neue Regular";
    color: white;
    text-align: center;
}

.route{
    display: flex;
    padding: 2rem 3rem;
    padding-bottom: 1rem;
}

.chain-input{
    color: white;
    font-size: 2rem;
    font-family: "Bebas Neue Book";
    display: flex;
}

.tag{
    color: white;
    font-size: 2rem;
    font-family: "Bebas Neue Book";
}

.chain-pick{
    border-radius: 5px;
    border: 1px solid white;
    margin: 0 1.5rem;
    font-family: "Bebas Neue Regular";
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: fit-content;
    transition: 0.3s;
    overflow: hidden;
}

.chain-pick:hover{
    transition: 0.3s;
    border-color: #F48825;
}

.active-chain{
    width: 12rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Bebas Neue Regular";
    font-size: 1.4rem;
    transition: 0.3s;
    cursor: pointer;
}

.active-chain:hover{
    transition: 0.3s;
    border-color: #F48825;
    color: #F48825;
}

.chain{
    padding: 0.5rem 0;
    cursor: pointer;
}

.chain:hover{
    color:#F48825;
}

.counter-bridge{
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    padding-top: 0;
}

.bridge{
    width: 2rem;
    height: 2rem;
    font-size: 1.8rem;
}

.fee{
    text-align: center;
    padding-top: 1.2rem;
    padding-bottom: 0.5rem;
    margin-bottom: auto;
    font-size: 1.5rem;
}

.bold{
    margin-left: 0.3rem;
    font-family: "Bebas Neue Regular";
    font-size: 1.4rem;
}

.button-wrap{
    display: flex;
    justify-content: center;
}

.count-bridge{
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-size: 1.8rem;
    font-family: "Bebas Neue Regular";
    text-align: center;
}
