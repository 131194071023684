.Mint{
    margin-left: auto;
    width: 40%;
    border-radius: 5px;
    border: 1px solid white;
    padding: 1.4rem;
    min-width: 33rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.nft-about{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.nft-pic{
    width: 55%;
    min-width: 11rem;
    max-width: 15rem;
}

.pic{
    width: 100%;
    border-radius: 5px;
}

.info{
    width: 50%;
    min-width: 15.5rem;
    display: flex;
    flex-direction: column;
    color: white;
}

.name{
    font-size: 2.5rem;
    font-family: "Bebas Neue Regular";
}

.count{
    font-size: 1.7rem;
    font-family: "Bebas Neue Book";
}

.chains-balance{
    margin-top: 1rem;
}

.description{
    font-size: 1.7rem;
    font-family: "Bebas Neue Book";
    padding: 0.5rem 0;
}

.mint-function{
    margin-top: 1rem;
    display: flex;
    width: 100%;
}

.button{
    width: 55%;
    min-width: 11rem;
    max-width: 15rem;
    color: white;
    font-size: 2.2rem;
    font-family: "Bebas Neue Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F48825;
    border-radius: 5px;
    line-height: 1.9rem;
    padding-top: 0.7rem;
    padding-bottom: 0.6rem;
    transition: 0.3s;
    border: 1px solid #F48825;
    cursor: pointer;
}

.button:hover{
    transition: 0.3s;
    border-color: #F48825;
    color: #F48825;
    background-color: transparent;
}

.counter{
    display: flex;
    align-items: center;
    margin: 0 1.5rem;
}

.minus-wrap{
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.minus{
    height: 2px;
    width: 24px;
    background-color: white;
}

.count-mint{
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-size: 2.2rem;
    font-family: "Bebas Neue Regular";
    text-align: center;
}

.plus{
    display:inline-block;
    width:50px;
    height:50px;
    
    background:
      linear-gradient(#fff,#fff),
      linear-gradient(#fff,#fff);
    background-position:center;
    background-size: 48% 1.7px,1.7px 48%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
    cursor: pointer;
}

.value{
    display: flex;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    font-family: "Bebas Neue Regular";
}

.sub{
    width: 20px;
    height: 1.7px;
}

.add{
    background-size: 44% 1.7px,1.7px 44%;
}

.am{
    font-size: 1.7rem;
}

@media (max-width: 1400px) {
    .Main{
        flex-direction: column;
        align-items: center;
    }

    .from{
        width: 100px;
        margin-bottom: 20px;
    }

    .to{
        width: 100px;
    }

    .counter{
        margin: 0 auto;
        padding: 0 0.4rem;
    }

    .Header{
        min-width: none;
    }

    .head-wrap{
        min-width: 0;
    }

    .chain-pick{
        margin: 0 70px;
    }

    .Mint{
        margin: 0;
        width: 50%;
        min-width: 250px;
    }

    .Bridge{
        width: 50%;
        min-width: 250px;
        margin-bottom: 30px;
    }

    .route{
        flex-direction: column;
    }

    .nft-pic{
        min-width: 0;
    }

    .info{
        min-width: 0;
    }

    .button{
        min-width: 0;
    }
}

@media (max-width: 1200px) {
    .Mint{
        display: flex;
        flex-direction: column;
    }
    .nft-about{
        flex-direction: column;
    }

    .nft-pic{
        width: 100%;
        max-width: none;
        margin-bottom: 20px;
    }

    .info{
        width: 100%;
    }

    .mint-function{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 160px;
    }
}

@media (max-width: 1000px) {
    .chain-pick{
        margin: 0;
        margin-left: auto;
    }
}

@media (max-width: 400px) {
    .lz-logo{
        display: none;
    }

    .x{
        display: none;
    }
}

@media (max-width: 760px) {
    .minus{
        width: 14px;
    }

    .counter-bridge{
        padding: 0rem 2rem;
    }

    .plus{
        background-size: 40% 1.7px,1.7px 40%;
    }
}

@media (max-width: 640px){
    .logo{
        flex-direction: column;
    }

    .route{
        padding: 2rem 2rem;
    }
}