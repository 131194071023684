.Header{
    width: 85%;
    padding: 2rem 0;
    max-width: 85rem;
}

.head-wrap{
    min-width: 34rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    display: flex;
    align-items: center;
}

.fronk-logo{
    color: white;
    font-family: "Bebas Neue Regular";
    font-size: 2.7rem;
    line-height: 2.2rem;
}

.x{
    padding: 0 1rem;
}

.connect{
    color: white;
    font-family: "Bebas Neue Regular";
    font-size: 1.6rem;
    border-radius: 5px;
    padding: 0.3rem 1.5rem;
    border: 1px solid white;
    cursor: pointer;
    transition: 0.3s;
}

.connect:hover{
    transition: 0.3s;
    border-color: #F48825;
    color: #F48825;
}