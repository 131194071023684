.SelectChain{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s;
}

.SelectChain.active{
    transition: 0.3s;
    opacity: 1;
    pointer-events: all;
}

.SelectChain-content{
    border-radius: 5px;
    background-color: #1A1A1A;
    width: 25%;
    height: fit-content;
    padding: 1rem 2rem;
    padding-bottom: 2rem;
    border: 1px solid white;
}

.chains{
    margin-top: 1rem;
}

.big-tag{
    color: white;
    font-family: "Bebas Neue Regular";
    font-size: 2.2rem;
}

.chain{
    text-align: center;
    padding: 1rem 0;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    font-family: "Bebas Neue Regular";
    font-size: 1.5rem;
}