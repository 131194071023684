@font-face {
  font-family: 'Bebas Neue Regular';
  src: url('./fonts/BebasNeue.otf');
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('./fonts/BebasNeueBook.otf');
}

.App{
  display: flex;
  align-items: center;
  flex-direction: column;
}